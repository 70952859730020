@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
	.container {
		@apply mx-auto max-w-[575px] w-full;
	}
}
body {
	@apply bg-black;
}
